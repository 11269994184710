export const GET_ADMINISTRATORS_LIST_REQUEST = 'GET_ADMINISTRATORS_LIST_REQUEST';
export const GET_ADMINISTRATORS_LIST_SUCCESS = 'GET_ADMINISTRATORS_LIST_SUCCESS';
export const GET_ADMINISTRATORS_LIST_FAILURE = 'GET_ADMINISTRATORS_LIST_FAILURE';

export const ADMIN_EDIT_USER_REQUEST = 'ADMIN_EDIT_USER_REQUEST';
export const ADMIN_EDIT_USER_SUCCESS = 'ADMIN_EDIT_USER_SUCCESS';
export const ADMIN_EDIT_USER_FAILURE = 'ADMIN_EDIT_USER_FAILURE';

export const ADMIN_INVITE_USER_REQUEST = 'ADMIN_INVITE_USER_REQUEST';
export const ADMIN_INVITE_USER_SUCCESS = 'ADMIN_INVITE_USER_SUCCESS';
export const ADMIN_INVITE_USER_FAILURE = 'ADMIN_INVITE_USER_FAILURE';

export const GET_SALES_REQUEST = 'GET_SALES_REQUEST';
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS';
export const GET_SALES_FAILURE = 'GET_SALES_FAILURE';
