export const GET_ALL_CLIENTS_REQUEST = 'GET_ALL_CLIENTS_REQUEST';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';

export const GET_CLIENT_HEADER_REQUEST = 'GET_CLIENT_HEADER_REQUEST';
export const GET_CLIENT_HEADER_SUCCESS = 'GET_CLIENT_HEADER_SUCCESS';
export const GET_CLIENT_HEADER_FAILURE = 'GET_CLIENT_HEADER_FAILURE';

export const GET_CLIENT_REFERRALS_REQUEST = 'GET_CLIENT_REFERRALS_REQUEST';
export const GET_CLIENT_REFERRALS_SUCCESS = 'GET_CLIENT_REFERRALS_SUCCESS';
export const GET_CLIENT_REFERRALS_FAILURE = 'GET_CLIENT_REFERRALS_FAILURE';

export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';

export const EDIT_COMMENT_REQUEST = 'EDIT_COMMENT_REQUEST';
export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
export const EDIT_COMMENT_FAILURE = 'EDIT_COMMENT_FAILURE';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

export const GET_SALES_REQUEST = 'GET_SALES_REQUEST';
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS';
export const GET_SALES_FAILURE = 'GET_SALES_FAILURE';

export const GET_CLIENT_STATISTICS_REQUEST = 'GET_CLIENT_STATISTICS_REQUEST';
export const GET_CLIENT_STATISTICS_SUCCESS = 'GET_CLIENT_STATISTICS_SUCCESS';
export const GET_CLIENT_STATISTICS_FAILURE = 'GET_CLIENT_STATISTICS_FAILURE';

export const GET_CLIENT_PROFILE_REQUEST = 'GET_CLIENT_PROFILE_REQUEST';
export const GET_CLIENT_PROFILE_SUCCESS = 'GET_CLIENT_PROFILE_SUCCESS';
export const GET_CLIENT_PROFILE_FAILURE = 'GET_CLIENT_PROFILE_FAILURE';

export const UPDATE_CLIENT_PROFILE_REQUEST = 'UPDATE_CLIENT_PROFILE_REQUEST';
export const UPDATE_CLIENT_PROFILE_SUCCESS = 'UPDATE_CLIENT_PROFILE_SUCCESS';
export const UPDATE_CLIENT_PROFILE_FAILURE = 'UPDATE_CLIENT_PROFILE_FAILURE';

export const GET_CLIENT_DOCUMENTS_REQUEST = 'GET_CLIENT_DOCUMENTS_REQUEST';
export const GET_CLIENT_DOCUMENTS_SUCCESS = 'GET_CLIENT_DOCUMENTS_SUCCESS';
export const GET_CLIENT_DOCUMENTS_FAILURE = 'GET_CLIENT_DOCUMENTS_FAILURE';

export const GET_CLIENT_PAYMENTS_REQUEST = 'GET_CLIENT_PAYMENTS_REQUEST';
export const GET_CLIENT_PAYMENTS_SUCCESS = 'GET_CLIENT_PAYMENTS_SUCCESS';
export const GET_CLIENT_PAYMENTS_FAILURE = 'GET_CLIENT_PAYMENTS_FAILURE';

export const GET_CLIENT_TRANSACTIONS_REQUEST = 'GET_CLIENT_TRANSACTIONS_REQUEST';
export const GET_CLIENT_TRANSACTIONS_SUCCESS = 'GET_CLIENT_TRANSACTIONS_SUCCESS';
export const GET_CLIENT_TRANSACTIONS_FAILURE = 'GET_CLIENT_TRANSACTIONS_FAILURE';

export const CHANGE_CLIENT_STATUS_REQUEST = 'CHANGE_CLIENT_STATUS_REQUEST';
export const CHANGE_CLIENT_STATUS_SUCCESS = 'CHANGE_CLIENT_STATUS_SUCCESS';
export const CHANGE_CLIENT_STATUS_FAILURE = 'CHANGE_CLIENT_STATUS_FAILURE';

export const SEND_CONFIRMATION_LINK_REQUEST = 'SEND_CONFIRMATION_LINK_REQUEST';
export const SEND_CONFIRMATION_LINK_SUCCESS = 'SEND_CONFIRMATION_LINK_SUCCESS';
export const SEND_CONFIRMATION_LINK_FAILURE = 'SEND_CONFIRMATION_LINK_FAILURE';

export const GET_CLIENT_BANK_DETAILS_REQUEST = 'GET_CLIENT_BANK_DETAILS_REQUEST';
export const GET_CLIENT_BANK_DETAILS_SUCCESS = 'GET_CLIENT_BANK_DETAILS_SUCCESS';
export const GET_CLIENT_BANK_DETAILS_FAILURE = 'GET_CLIENT_BANK_DETAILS_FAILURE';

export const GET_CLIENT_PROFILE_COMMENTS_REQUEST = 'GET_CLIENT_PROFILE_COMMENTS_REQUEST';
export const GET_CLIENT_PROFILE_COMMENTS_SUCCESS = 'GET_CLIENT_PROFILE_COMMENTS_SUCCESS';
export const GET_CLIENT_PROFILE_COMMENTS_FAILURE = 'GET_CLIENT_PROFILE_COMMENTS_FAILURE';

export const ASSIGN_SALES_REQUEST = 'ASSIGN_SALES_REQUEST';
export const ASSIGN_SALES_SUCCESS = 'ASSIGN_SALES_SUCCESS';
export const ASSIGN_SALES_FAILURE = 'ASSIGN_SALES_FAILURE';

export const CLEAR_CLIENT_DATA = 'CLEAR_CLIENT_DATA';
