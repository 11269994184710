import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

//reducers

import appReducer from './appReducer';
import user from './userReducer';
import administrators from './administratorsReducer';
import clients from './clientsReducer';
import settings from './settingsReducer';
import options from './options';
import dashboard from './dashboardReducer';


const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['options'],
};

const rootReducer = combineReducers({
    appReducer,
    dashboard,
    user,
    settings,
    administrators,
    clients,
    options,
});

export default persistReducer(rootPersistConfig, rootReducer)
