import * as clientsActions from '../../actions/clientsActions/constants'
import * as administratorsActions from '../../actions/administratorsActions/constants'

const initialState = {
    administrators: null,
    sales: null,
    isLoading: true,
    processedAdmins: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case administratorsActions.GET_ADMINISTRATORS_LIST_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case administratorsActions.GET_ADMINISTRATORS_LIST_SUCCESS:
            return {
                ...state,
                administrators: action.payload,
                isLoading: false
            };
        case administratorsActions.GET_ADMINISTRATORS_LIST_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case administratorsActions.ADMIN_INVITE_USER_REQUEST:
            return {
                ...state,
                processedAdmins: true
            };
        case administratorsActions.ADMIN_INVITE_USER_SUCCESS:
            return {
                ...state,
                processedAdmins: false
            };
        case administratorsActions.ADMIN_INVITE_USER_FAILURE:
            return {
                ...state,
                processedAdmins: false
            };
        case administratorsActions.GET_SALES_REQUEST:
            return {
                ...state
            };
        case administratorsActions.GET_SALES_SUCCESS:
            return {
                ...state,
                sales: action.payload,
            };
        case administratorsActions.GET_SALES_FAILURE:
            return {
                ...state
            };
        default:
            return state
    }
}